import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addOffer } from "../../../Redux/OfferSlice";

const AddOffer = ()=> {
    const [formData, setFormData] = useState({})
    const [imageFile, setImageFile] = useState(null);
    const admin = JSON.parse(localStorage.getItem('offerAdmin'))
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log('file', file)
            setImageFile(file);
      };

    const handleInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value})
    }
    const handleLogin = async (e)=> {
        e.preventDefault();
        console.log('imageFile', imageFile)
        const form = new FormData();
        form.append('adminId', admin._id);
        form.append('campaign_logo', imageFile);
        form.append('campaign_name', formData?.campaign_name);
        form.append('KPI', formData?.KPI);
        form.append('Payout', formData?.Payout);
        form.append('Geo', formData?.Geo);
        form.append('preview_link', formData?.preview_link);
        form.append('mmp', formData?.mmp);
        form.append('os', formData?.os);
        form.append('RejectionCriteria', formData?.RejectionCriteria);
        form.append('MmpAvailable', formData?.MmpAvailable);
        form.append('VTA', formData?.VTA);
        form.append('category', formData?.category);
        form.append('offerModel', formData?.offerModel);
        const offerRes = await dispatch(addOffer(form));
        const res = offerRes.payload; 
        if(res.responseCode === 200){
            toast.success(res.responseMessage)
            navigate('/admin')
        }else{
            toast.error(res.responseMessage)
        }
        console.log('addofferRes', res)
    }
    return (
        <>
        <div className="container mx-auto pt-20 pb-10 main-bg">
            <div className="main flex justify-center grow">
                <div className="w-4/5 py-4 px-4 text-left">
                    <div className="rounded main-bg px-4 py-4">
                        <div className="w-4/5 bg-white py-4 px-4 rounded text-left mx-auto shadow">
                        <h2 className="font-bold text-xl text-gray-700 pb-4">Create offer</h2>
                            <form>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="campaign_logo">Campaign Logo</label>
                                        <input type="file" 
                                            id="campaign_logo" 
                                            name="campaign_logo" 
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            required  
                                            className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="campaign_name">Campaign Name</label>
                                        <input type="text" id="campaign_name" name="campaign_name" value={formData?.campaign_name} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="KPI">KPI</label>
                                        <input type="text" id="KPI" placeholder="" name="KPI" value={formData?.KPI} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="Payout">Payout</label>
                                        <input type="text" id="Payout" name="Payout" value={formData?.Payout} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="Geo">Geo</label>
                                        <input type="text" id="Geo" name="Geo" value={formData?.Geo} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="offerModel">Offer Modal</label>
                                        <input type="text" id="offerModel" name="offerModel" value={formData?.offerModel} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="mmp">MMP</label>
                                        <input type="text" id="mmp" name="mmp" value={formData?.mmp} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="os">os</label>
                                        <input id="os" name="os" value={formData?.os} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="RejectionCriteria">Rejection Criteria</label>
                                        <input type="text" id="RejectionCriteria" name="RejectionCriteria" value={formData?.RejectionCriteria} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="MmpAvailable">Mmp Available</label>
                                        <input id="MmpAvailable" name="MmpAvailable" value={formData?.MmpAvailable} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>  
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="VTA">VTA</label>
                                        <input type="text" id="VTA" name="VTA" value={formData?.VTA} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="category">Category</label>
                                        <input id="category" name="category" value={formData?.category} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>  
                                </div>
                                <div className="flex justify-between items-center">
                                <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="preview_link">Preview Link</label>
                                        <textarea type="text" id="preview_link" name="preview_link" value={formData?.preview_link} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>  
                                </div>
                            <button type="submit" onClick={handleLogin} className="py-2 px-4 bg-sky-700 rounded text-sky-100 hover:text-white">Submit</button>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        </>
    )
}

export default AddOffer;