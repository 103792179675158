import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BaseUrl from '../Api/BaseUrl';

export const fetchUserWallet = createAsyncThunk(
    'user/viewWallet',
    async (userId) => {
        const response = await fetch(`${BaseUrl}user/viewWallet?userId=${userId}`);
        const responseData = await response.json();
        return responseData
      }
)

export const UserWalletSlice = createSlice({
    name: 'userWallet',
    initialState: {
        wallet: null,
        details: [],
        loading : false,
        error : null
      },

      reducers: {
    
      },

      extraReducers: (builder) => {
        // fetch builder
       builder.addCase(fetchUserWallet.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchUserWallet.fulfilled, (state, action) => {
         state.wallet =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchUserWallet.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });

    }
})

export default UserWalletSlice.reducer