import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BaseUrl from '../Api/BaseUrl';

export const fetchAdvertiserList = createAsyncThunk(
    'admin/advertiserList',
    async (adminId) => {
        const response = await fetch(`${BaseUrl}admin/advertiserList?adminId=${adminId}`);
        const responseData = await response.json();
        return responseData
      }
)
export const addAdvertiser = createAsyncThunk(
    'admin/addAdvertiser',
    async (formData) => {
        const response = await fetch(`${BaseUrl}admin/addAdvertiser`, {
            method: 'POST',
            headers: {
                'accept': 'application/json'
            },
            body: new URLSearchParams(formData)
        });
        const responseData = await response.json();
        return responseData
      }
)

export const AdvertiserSlice = createSlice({
    name: 'advertiser',
    initialState: {
        list: [],
        details: [],
        loading : false,
        error : null
      },

      reducers: {
    
      },

      extraReducers: (builder) => {
        // fetch builder
       builder.addCase(fetchAdvertiserList.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchAdvertiserList.fulfilled, (state, action) => {
         state.list =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchAdvertiserList.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // Add builder
       builder.addCase(addAdvertiser.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(addAdvertiser.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(addAdvertiser.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
    }
})

export default AdvertiserSlice.reducer