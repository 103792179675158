import './App.css';
import Navbar from './components/global/Navbar';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from './pages/Home';
import Footer from './components/global/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import Contact from './pages/Contact';
import AdminLogin from './pages/Admin/login';
import Admin from './pages/Admin';
import AddOffer from './pages/Admin/offer/Create';
import Offer from './pages/Admin/offer';
import OfferDetails from './pages/Admin/offer/Details';
import { useEffect } from 'react';

function App() {
  const admin = JSON.parse(localStorage.getItem('offerAdmin'))
  const currentURL = window.location.href;
  const isAdminLoginPage = currentURL.includes('/admin/login');
  useEffect(()=>{
    if(isAdminLoginPage){
      console.log('true')
    }
  },[])
 
  return (
    
    <div className="App">
      <ToastContainer />
      <Provider store={store}>
       <BrowserRouter>
      {!isAdminLoginPage && <Navbar />}
      <Routes>
        <Route path='/'>
          <Route path="/" element={<Home tab='Offers' title='description'/>} />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route path='/admin'>
          <Route path="login" element={<AdminLogin />} />
          <Route path="" element={<Admin />} />
          <Route path="addOffer" element={<AddOffer />} />
          <Route path="offer" element={<Offer />} />
          <Route path="offerdetails/:id" element={<OfferDetails />} />
        </Route>
      </Routes>
      {!isAdminLoginPage && <Footer />}
    </BrowserRouter>
    </Provider>
    </div>
  );
}

export default App;
