import React, { useRef } from "react";
import { toast } from "react-toastify";

const KpiModal = ({isModal, setIsModal, kpi})=> {
    const textToCopyRef = useRef(null);

  const handleCopyClick = () => {
    // Select the text inside the input field
    const range = document.createRange();
    range.selectNode(textToCopyRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    // Execute copy command
    document.execCommand('copy');

    // Deselect the text
    window.getSelection().removeAllRanges();
    toast.success('Coppied')
  };
    return (
        <>
        {/* Main modal */}
{isModal && <div class="postback-modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full flex">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Kpi detail
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={()=> setIsModal(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div class="p-4 md:p-5 space-y-4 text-left flex justify-between items-center">
                <p class="text-base leading-relaxed text-gray-700 dark:text-gray-400">
                {kpi}
                </p>
            </div>
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button type="button" class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={()=> setIsModal(false)}>Close</button>
            </div>
        </div>
    </div>
</div>}
        </>
    )
}
export default KpiModal;