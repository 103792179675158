import { configureStore } from "@reduxjs/toolkit";
import AdvertiserReducer from "./AdvertiserSlice";
import OfferReducer from "./OfferSlice";
import UserWalletReducer from "./UserWalletSlice";
import UserReducer from "./UserSlice";
 
export const store = configureStore({
  reducer: {
    advertiser: AdvertiserReducer,
    offer: OfferReducer,
    userWallet: UserWalletReducer,
    user: UserReducer,
  },
});