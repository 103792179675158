import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.png'

const Navbar = ()=> {
    const [scrolling, setScrolling] = useState(false);
    const admin = JSON.parse(localStorage.getItem('offerAdmin'))
    const navigate = useNavigate()
    const currentURL = window.location.href;
    const isAdminPage = currentURL.includes('/admin');

    useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > 50) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    const headerStyle = {
        backgroundColor: scrolling ? '#fff' : '#ffffff8c',
        transition: 'background-color 0.3s ease', 
    };
    const handleLogout = ()=> {
        localStorage.removeItem('offerAdmin');
        navigate('./login')
    }
    return (
        <>
        <div className="navbar-container">
            <header style={headerStyle} className=" px-4">
                <div  className="">
                    <div className="flex items-center justify-between">
                        <div className="logo w-1/5">
                            <a href="/"><img src={logo} alt="logo" width={scrolling ? 80 : 120} /></a>
                        </div>
                        <div className="w-2/5 flex justify-end gap-4">
                        {isAdminPage && <div className="adminMenu px-2 py-2">
                            <ul className="flex gap-4">
                                <li><Link className="py-2 px-4 rounded bg-gray-600 text-white" to='/admin/offer'>Offer</Link></li>
                                <li><Link className="py-2 px-4 rounded bg-gray-600 text-white" to='/admin/addoffer'>Create offer</Link></li>
                            </ul>
                        </div>}
                        { !isAdminPage ? <><div className="flex justify-end gap-4"><button><Link  to='https://partners.adomobi.com/' className="py-2 px-4 text-white bg-orange-500 text-base rounded"> <i className="fa-solid fa-arrow-right-from-bracket"></i> Login</Link></button>
                            <button><Link  to='https://partners.adomobi.com/publisher/signup' className="py-2 px-2 text-orange-500 border border-orange-500 text-base rounded">Affiliates Signup</Link></button></div></>:<div> 
                        {admin ? <button className="py-2 px-2 text-white bg-orange-500 text-base rounded" onClick={handleLogout}><i className="fa-solid fa-arrow-right-to-bracket"></i> Logout</button> :
                        <></>}</div>}
                        </div>
                    </div>
                </div>
            </header>
        </div>
        </>
    )
}
export default Navbar;