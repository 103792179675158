import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOfferDetail, updateOffer } from "../../../Redux/OfferSlice";
import { toast } from "react-toastify";

const OfferDetails = ()=> {
    const {id} = useParams()
    const [isEditable, setIsEditable] = useState(false)
    const [formData, setFormData] = useState()
    const [imageFile, setImageFile] = useState(null);
    const dispatch =  useDispatch()
    const offerDetail = useSelector((state)=> state.offer.details);
    const admin = JSON.parse(localStorage.getItem('offerAdmin'))
    const navigate = useNavigate()

    useEffect(()=> {
        setFormData({...offerDetail, ...formData})
    },[offerDetail])
    useEffect(()=> {
        dispatch(fetchOfferDetail({adminId: admin._id, offerId: id}))
    },[])

    const handleInput = (e)=> {
        const FormData = {};
        const elementName = e.target.name;
        const elementValue = e.target.value;
        FormData[elementName] = elementValue;
        setFormData({...formData, ...FormData});    
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log('imageFile',file)
        setImageFile(file);
      };
      const handleSubmit = async (e)=> {
        e.preventDefault();
        console.log('imageFile', imageFile)
        const form = new FormData();
        form.append('adminId', admin._id);
        form.append('campaign_logo', imageFile);
        form.append('campaign_name', formData?.campaign_name);
        form.append('KPI', formData?.KPI);
        form.append('Payout', formData?.Payout);
        form.append('Geo', formData?.Geo);
        form.append('preview_link', formData?.preview_link);
        form.append('mmp', formData?.mmp);
        form.append('os', formData?.os);
        form.append('RejectionCriteria', formData?.RejectionCriteria);
        form.append('MmpAvailable', formData?.MmpAvailable);
        form.append('VTA', formData?.VTA);
        form.append('category', formData?.category);
        form.append('offerModel', formData?.offerModel);
        const offerUpdateRes = await dispatch(updateOffer({offerId: id, formData: form}));
        const res = offerUpdateRes.payload; 
        if(res.responseCode === 200){
            toast.success(res.responseMessage)
            setIsEditable(false)
        }else{
            toast.error(res.responseMessage)
        }
        console.log('addofferRes', res)
      }
    return (
        <>
        <div className="container mx-auto px-20 pt-20 pb-4 main-bg">
            <div className="flex justify-center">
                <div className="w-4/5 py-4 px-4 text-left">
                        <div className="rounded main-bg px-4 py-4">
                            <div className="w-full bg-white py-4 px-4 rounded text-left shadow">
                            <form onSubmit={handleSubmit}>
                                <div className="flex justify-between items-center pb-2">
                                    <h2 className="font-bold text-xl text-gray-700 pb-2">{!isEditable ? 'Offer details' : 'Offer Edit'}</h2>
                    
                                {isEditable? <div className="">
                                                <button type="submit" className="py-1 px-2 text-gray-500 border bg-sky-700 rounded text-white">Save</button>
                                                <button type="button" className="py-1 px-2 text-gray-500 border border-gray-700 rounded ml-2" onClick={()=> setIsEditable(false)}>Cancel</button>
                                                </div>:
                                                <button type='button' className="py-1 px-2 text-gray-500 border border-gray-700 rounded" onClick={()=> setIsEditable(true)}>Edit</button>}
                                </div>
                                <hr />
                                
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                        <p className="font-bold text-gray-700 w-2/4">Icon:</p>
                                        {!isEditable ? <img src={offerDetail?.campaign_logo} alt='offer icon' className="w-10" />: 
                                            <input type="file"className="py-2 px-2 border border-grey-300 rounded"
                                                name="campaign_logo" 
                                                accept="image/*"
                                                onChange={handleImageChange}/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                        <p className="font-bold text-gray-700 w-2/4">Campaign Name</p>
                                        {!isEditable ? <span>{offerDetail?.campaign_name}</span>:
                                            <input type="text" className="py-2 px-2 border border-grey-300 rounded w-full" 
                                                name="campaign_name" 
                                                value={formData?.campaign_name} 
                                                onChange={handleInput}
                                                required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                        <p className="font-bold text-gray-700 w-2/4">KPI:</p>
                                        {!isEditable ? <span>{offerDetail?.KPI}</span> :
                                            <input type="text" className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="KPI" 
                                            value={formData?.KPI} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                        <p className="font-bold text-gray-700 w-2/4">Category:</p>
                                        {!isEditable ? <span>{offerDetail?.category}</span> :
                                            <input type="text" className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="category" 
                                            value={formData?.category} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                        <p className="font-bold text-gray-700 w-2/4">Offer Model:</p>
                                        {!isEditable ? <span>{offerDetail?.offerModel}</span> : 
                                            <input type="text" className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="offerModel" 
                                            value={formData?.offerModel} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                        <p className="font-bold text-gray-700 w-2/4">Payout:</p>
                                        {!isEditable ? <span>{offerDetail?.Payout}</span> :
                                            <input type="text" className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="Payout" 
                                            value={formData?.Payout} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                        <p className="font-bold text-gray-700 w-2/4">Geo:</p>
                                        {!isEditable ? <span>{offerDetail?.Geo}</span> :
                                            <input type="text" className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="Geo" 
                                            value={formData?.Geo} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                        <p className="font-bold text-gray-700 w-1/4">Preview Link:</p>
                                        {!isEditable ? <p className="w-3/4">{offerDetail?.preview_link} </p> :
                                            <textarea type="text" className="py-2 px-2 border border-grey-300 rounded w-3/4" 
                                            name="preview_link" 
                                            value={formData?.preview_link} 
                                            onChange={handleInput}
                                            required />
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                        <p className="font-bold text-gray-700 w-2/4">MMP:</p>
                                        {!isEditable ? <span>{offerDetail?.mmp}</span> :
                                            <input className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="mmp" 
                                            value={formData?.mmp} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                        <p className="font-bold text-gray-700 w-2/4">OS:</p>
                                        {!isEditable ? <span>{offerDetail?.os}</span> :
                                            <input className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="os" 
                                            value={formData?.os} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                        <p className="font-bold text-gray-700 w-2/4">Rejection Criteria:</p>
                                        {!isEditable ? <span>{offerDetail?.RejectionCriteria}</span> :
                                            <input className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="RejectionCriteria" 
                                            value={formData?.RejectionCriteria} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                        <p className="font-bold text-gray-700 w-2/4">VTA:</p>
                                        {!isEditable ? <span>{offerDetail?.VTA}</span> :
                                            <input className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="VTA" 
                                            value={formData?.VTA} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                    <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                        <p className="font-bold text-gray-700 w-2/4">Mmp Available:</p>
                                        {!isEditable ? <span>{offerDetail?.MmpAvailable}</span> :
                                            <input className="py-2 px-2 border border-grey-300 rounded w-full" 
                                            name="MmpAvailable" 
                                            value={formData?.MmpAvailable} 
                                            onChange={handleInput}
                                            required/>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default OfferDetails;