import React, { useState } from "react";

const Footer = ()=> {
    const [isModal, setIsModal] = useState(false)
    return (
        <>
            <div className="px-20 py-3">
                <div className="text-center">
                <p className="text-gray-500 font-light text-sm text-center"><b>© AdoMobi</b> - All Right Reserved</p>
                </div>
            </div>
        </>
    )
}
export default Footer