import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Admin = ()=> {
    const admin = JSON.parse(localStorage.getItem('offerAdmin'))
    const navigate = useNavigate()
    useEffect(()=>{
        if(!admin){
            navigate('./login')
        }
    })
    return (
        <>
        <div className="py-20 px-20 h-screen">
            <div className="flex justify-between py-10">
                <h2 className="">Admin</h2>
                <button className="bg-sky-500 py-2 px-2 text-white rounded"><Link to='./addOffer'>Create Offer</Link></button>
            </div>
        </div>
        </>
    )
}

export default Admin;