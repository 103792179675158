import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BaseUrl from '../Api/BaseUrl';

export const fetchUserDetail = createAsyncThunk(
    'admin/view',
    async ({adminId, userId}) => {
        const response = await fetch(`${BaseUrl}admin/view?adminId=${adminId}&userId=${userId}`);
        const responseData = await response.json();
        return responseData
      }
)



export const UserSlice = createSlice({
    name: 'user',
    initialState: {
        list: [],
        details: null,
        loading : false,
        error : null
      },

      reducers: {
    
      },

      extraReducers: (builder) => {
        // fetch builder
       builder.addCase(fetchUserDetail.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchUserDetail.fulfilled, (state, action) => {
         state.details =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchUserDetail.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
    }
})

export default UserSlice.reducer